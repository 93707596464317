var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showInformations,
            expression: "showInformations",
          },
        ],
        staticClass: "wrapper",
      },
      [
        _c(
          "div",
          { staticClass: "wrapper__card" },
          [
            _c("div", { staticClass: "wrapper__card__header" }, [
              _vm._m(0),
              _c("div", { staticClass: "right" }, [
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        rounded: "",
                        primary: "",
                        text: "adicionar",
                        icon: "mdi-plus",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.resetAddModal()
                          _vm.dialogAdd = true
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        rounded: "",
                        primary: "",
                        text: "exportar",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.downloadCsv()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        rounded: "",
                        primary: "",
                        text: "baixar template",
                        small: "",
                        href: "/optouttemplate.csv",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("z-btn", {
                      attrs: {
                        rounded: "",
                        primary: "",
                        text: "importar",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.ImportCsv()
                        },
                      },
                    }),
                    _c("input", {
                      ref: "file",
                      staticStyle: { display: "none" },
                      attrs: { type: "file", accept: ".csv" },
                      on: { change: _vm.previewFiles },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("v-row", { staticClass: "left" }, [
              _c(
                "div",
                { staticClass: "filterColumn" },
                [
                  _c("z-input", {
                    attrs: {
                      placeholder: "Pesquisar",
                      prependIcon: "$search_rounded",
                      type: "text",
                      hideDetails: true,
                    },
                    model: {
                      value: _vm.optoutFilter.searchText,
                      callback: function ($$v) {
                        _vm.$set(_vm.optoutFilter, "searchText", $$v)
                      },
                      expression: "optoutFilter.searchText",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filterColumn" },
                [
                  _c("z-select", {
                    staticClass: "select",
                    attrs: {
                      hideDetails: true,
                      placeholder: "Tipo",
                      items: _vm.filterType,
                      itemText: "name",
                      itemValue: "value",
                    },
                    model: {
                      value: _vm.optoutFilter.searchType,
                      callback: function ($$v) {
                        _vm.$set(_vm.optoutFilter, "searchType", $$v)
                      },
                      expression: "optoutFilter.searchType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filterColumn" },
                [
                  _c("z-btn", {
                    attrs: { rounded: "", primary: "", text: "buscar" },
                    on: {
                      click: function ($event) {
                        return _vm.getOptOutList()
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "wrapper__card__body" },
              [
                _c("z-pagination", {
                  attrs: {
                    "auto-pagination": false,
                    "icon-previous": "mdi-chevron-left",
                    "icon-next": "mdi-chevron-right",
                    "items-per-page": 10,
                    items: _vm.filtered ? _vm.filtered : _vm.paginatedItems,
                    "number-of-pages": _vm.totalPages,
                    "current-page": _vm.optoutFilter.page,
                  },
                  on: {
                    "click:next": _vm.goToNextPage,
                    "click:previous": _vm.backPreviousPage,
                    "change:page": function ($event) {
                      return _vm.selectPage($event)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "data",
                      fn: function ({ paginatedItems }) {
                        return [
                          _c(
                            "div",
                            [
                              _c("z-table", {
                                staticClass: "table",
                                attrs: {
                                  headers: _vm.headers,
                                  "fixed-header": "",
                                  height: "600",
                                  items: paginatedItems,
                                  "icon-next": "mdi-chevron-right",
                                  "icon-previous": "mdi-chevron-left",
                                },
                                on: { sort: _vm.sortTable },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "no-data",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "no-data-table" },
                                            [
                                              _c("h3", [
                                                _vm._v(
                                                  "Sem dados disponíveis."
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: `item.type`,
                                      fn: function ({ item }) {
                                        return [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.type
                                                      ? item.type
                                                      : "optout"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.created`,
                                      fn: function ({ item }) {
                                        return [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formateDateTime(
                                                      item.created
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.actions`,
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "actions" },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    color: "grey",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.deleteId =
                                                                            item._id
                                                                          _vm.dialogDelete = true
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "$delete_outline_rounded"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "white",
                                                        padding: "0.5rem",
                                                        "font-weight": "500",
                                                      },
                                                    },
                                                    [_vm._v("Apagar")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("z-card-modal", {
          attrs: { width: "400", title: "" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "delete-confirmation" }, [
                    _c(
                      "div",
                      { staticClass: "delete-confirmation__title mb-5" },
                      [
                        _c("span", [
                          _vm._v("Tem certeza que deseja apagar esse opt-out?"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "delete-confirmation--actions" },
                      [
                        _c("z-btn", {
                          attrs: { small: "", rounded: "", text: "cancelar" },
                          on: {
                            click: function ($event) {
                              _vm.dialogDelete = false
                            },
                          },
                        }),
                        _c("z-btn", {
                          attrs: {
                            rounded: "",
                            primary: "",
                            text: "apagar",
                            small: "",
                            isLoading: _vm.loadingDelete,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialogDelete,
            callback: function ($$v) {
              _vm.dialogDelete = $$v
            },
            expression: "dialogDelete",
          },
        }),
        _c("z-card-modal", {
          attrs: { width: "400", title: "" },
          on: {
            "click:outside": function ($event) {
              return _vm.resetAddModal()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "v-form",
                    {
                      ref: "formDialog",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {},
                        [
                          _c("span", { staticClass: "mb-5" }, [
                            _vm._v("Insira os dados:"),
                          ]),
                          _c("z-input", {
                            staticClass: "mt-3",
                            attrs: {
                              placeholder: "Telefone",
                              label: "Telefone",
                              mask: "+## (##) #####-####",
                            },
                            model: {
                              value: _vm.optOutAdd.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.optOutAdd, "number", $$v)
                              },
                              expression: "optOutAdd.number",
                            },
                          }),
                          _c("z-input", {
                            staticClass: "mt-3",
                            attrs: { label: "Email", placeholder: "Email" },
                            model: {
                              value: _vm.optOutAdd.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.optOutAdd, "email", $$v)
                              },
                              expression: "optOutAdd.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "delete-confirmation--actions" },
                        [
                          _c("z-btn", {
                            attrs: { small: "", rounded: "", text: "cancelar" },
                            on: {
                              click: function ($event) {
                                return _vm.resetAddModal()
                              },
                            },
                          }),
                          _c("z-btn", {
                            attrs: {
                              rounded: "",
                              primary: "",
                              text: "Adicionar",
                              small: "",
                              isLoading: _vm.loadingAdd,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addOptout()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialogAdd,
            callback: function ($$v) {
              _vm.dialogAdd = $$v
            },
            expression: "dialogAdd",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showInformations,
            expression: "!showInformations",
          },
        ],
        staticClass: "wrapper no-data",
      },
      [_c("div", { staticClass: "wrapper__card" }, [_c("z-loading")], 1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("span", [_vm._v("Consultar opt-outs")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }